var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.back },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.addPopupVisible,
            callback: function ($$v) {
              _vm.addPopupVisible = $$v
            },
            expression: "addPopupVisible",
          },
        },
        [
          _c("chapter-project", {
            attrs: { options: _vm.addPopupOptions, result: _vm.result },
            on: { select: _vm.handleAddSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.stampTypeVisible,
            callback: function ($$v) {
              _vm.stampTypeVisible = $$v
            },
            expression: "stampTypeVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: { options: _vm.stampTypeOptions, radio: _vm.radio },
            on: { select: _vm.handleStampTypeSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.caseListVisible,
            callback: function ($$v) {
              _vm.caseListVisible = $$v
            },
            expression: "caseListVisible",
          },
        },
        [
          _c("case-list", {
            attrs: { caseId: _vm.caseId },
            on: { select: _vm.handleCaseListSelect },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "oa-flow" }, [
        _c(
          "div",
          [
            _c("div", { staticClass: "oa-stamp" }, [
              _c(
                "div",
                {
                  staticClass: "oa-stamp-div",
                  on: { click: _vm.swtichChapterPro },
                },
                [
                  _c("div", { staticClass: "oa-stamp-title" }, [
                    _c("span", { staticClass: "oa-stamp-title-red" }, [
                      _vm._v("*"),
                    ]),
                    _c("span", [_vm._v("用章项目")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "oa-stamp-content" },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.chapterProText === "请选择文书"
                              ? ""
                              : "selsect-span",
                        },
                        [_vm._v(_vm._s(_vm.chapterProText))]
                      ),
                      _c("van-icon", {
                        attrs: { name: "arrow-down", color: "#CCCCCC" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm.title === "案件用章"
                ? _c(
                    "div",
                    {
                      staticClass: "oa-stamp-div",
                      on: { click: _vm.switchCaseList },
                    },
                    [
                      _c("div", { staticClass: "oa-stamp-title" }, [
                        _c("span", { staticClass: "oa-stamp-title-red" }, [
                          _vm._v("*"),
                        ]),
                        _c("span", [_vm._v("项目名称")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "oa-stamp-content" },
                        [
                          _c(
                            "span",
                            {
                              class:
                                _vm.caseName === "请选择案件"
                                  ? ""
                                  : "selsect-span",
                            },
                            [_vm._v(_vm._s(_vm.caseName))]
                          ),
                          _vm.caseNameIsClick
                            ? _c("van-icon", {
                                attrs: { name: "arrow-down", color: "#CCCCCC" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "oa-stamp-div",
                  on: { click: _vm.swtichStampType },
                },
                [
                  _c("div", { staticClass: "oa-stamp-title" }, [
                    _c("span", { staticClass: "oa-stamp-title-red" }, [
                      _vm._v("*"),
                    ]),
                    _c("span", [_vm._v("用章类型")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "oa-stamp-content" },
                    [
                      _c(
                        "span",
                        {
                          class:
                            _vm.stampTypeText === "请选择用章类型"
                              ? ""
                              : "selsect-span",
                        },
                        [_vm._v(_vm._s(_vm.stampTypeText))]
                      ),
                      _c("van-icon", {
                        attrs: { name: "arrow-down", color: "#CCCCCC" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm.title === "业务用章"
                ? _c("div", { staticClass: "oa-stamp-div" }, [
                    _c("div", { staticClass: "oa-stamp-title" }, [
                      _c("span", { staticClass: "oa-stamp-title-red" }),
                      _c("span", [_vm._v("收函方")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "oa-stamp-content" },
                      [
                        _c("van-field", {
                          attrs: {
                            placeholder: "请输入收函方",
                            "input-align": "right",
                          },
                          model: {
                            value: _vm.recipient,
                            callback: function ($$v) {
                              _vm.recipient = $$v
                            },
                            expression: "recipient",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.title === "业务用章"
                ? _c("div", { staticClass: "oa-stamp-div" }, [
                    _c("div", { staticClass: "oa-stamp-title" }, [
                      _c("span", { staticClass: "oa-stamp-title-red" }, [
                        _vm._v("*"),
                      ]),
                      _c("span", [_vm._v("申请份数")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "oa-stamp-content" },
                      [
                        _c("van-field", {
                          attrs: {
                            placeholder: "请输入申请份数",
                            "input-align": "right",
                            type: "digit",
                          },
                          model: {
                            value: _vm.number,
                            callback: function ($$v) {
                              _vm.number = $$v
                            },
                            expression: "number",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "oa-stamp-field" },
              [
                _c("van-field", {
                  staticClass: "oa-stamp-van-field",
                  attrs: {
                    rows: "2",
                    autosize: "",
                    label: "备注",
                    type: "textarea",
                    maxlength: "50",
                    placeholder: "请输入备注",
                    "show-word-limit": "",
                  },
                  model: {
                    value: _vm.remarks,
                    callback: function ($$v) {
                      _vm.remarks = $$v
                    },
                    expression: "remarks",
                  },
                }),
                _c("div", { staticClass: "oa-stamp-file" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../../../public/img/icons/u4647-1.png"),
                      alt: "",
                    },
                  }),
                  _c("input", {
                    staticClass: "filepath",
                    attrs: { type: "file", id: "file" },
                    on: { change: _vm.getImage },
                  }),
                ]),
              ],
              1
            ),
            _vm.collections.length > 0
              ? _c("div", { staticClass: "oa-stamp" }, [
                  _c(
                    "div",
                    { staticClass: "oa-stamp-image" },
                    _vm._l(_vm.collections, function (items, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "oa-stamp-image-box" },
                        [
                          items.type === "WORD"
                            ? _c("i", {
                                staticClass: "iconfont icon-word",
                                staticStyle: { color: "rgb(108, 168, 255)" },
                              })
                            : _vm._e(),
                          items.type === "EXCEL"
                            ? _c("i", {
                                staticClass: "iconfont icon-exl",
                                staticStyle: { color: "rgb(139, 221, 108)" },
                              })
                            : _vm._e(),
                          items.type === "PDF"
                            ? _c("i", {
                                staticClass: "iconfont icon-PDF",
                                staticStyle: { color: "rgb(62, 179, 240)" },
                              })
                            : _vm._e(),
                          items.type === "VIDEO"
                            ? _c("i", {
                                staticClass: "iconfont icon-shipin",
                                staticStyle: { color: "rgb(75, 158, 251)" },
                              })
                            : _vm._e(),
                          items.type === "AUDIO"
                            ? _c("i", {
                                staticClass: "iconfont icon-shipin",
                                staticStyle: { color: "rgb(75, 158, 251)" },
                              })
                            : _vm._e(),
                          items.type === "IMAGE"
                            ? _c("img", {
                                staticClass: "oa-stamp-image-img",
                                attrs: { src: items.content, alt: "" },
                              })
                            : _vm._e(),
                          _c("img", {
                            staticClass: "oa-stamp-image-delete",
                            attrs: {
                              src: require("../../../../../public/img/icons/icon_delete.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteImg(i)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            this.isAdd === 1 && this.case.progress === "CERTIFIED_FAILED"
              ? _c(
                  "div",
                  { staticClass: "oa-approval" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "oa-approval-title",
                        staticStyle: { "margin-bottom": "20px" },
                      },
                      [_vm._v(" 审批记录 ")]
                    ),
                    _c("approval-record", {
                      attrs: {
                        typeId: this.case.id,
                        type: "STAMPER_RECORD_ADD",
                      },
                    }),
                  ],
                  1
                )
              : _vm.approvalUserComp
              ? _c("approval-user", {
                  attrs: {
                    Approval: this.Approval,
                    CC: this.CC,
                    isAdd: _vm.isAdd,
                    approvalUserSource: _vm.approvalUser,
                    ccUserSource: _vm.ccUser,
                  },
                  on: {
                    userSelect: _vm.userSelect,
                    coopSelect: _vm.coopSelect,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "oa-button-view" }, [
          this.Approval.length > 0 && this.CC.length > 0
            ? _c(
                "button",
                {
                  staticClass: "oa-button-apply",
                  on: {
                    click: function ($event) {
                      return _vm.save("UNAUDITED")
                    },
                  },
                },
                [_vm._v(" 申请 ")]
              )
            : _vm._e(),
          _vm.caseNameIsClick === false
            ? _c(
                "button",
                {
                  staticClass: "oa-button-delete",
                  on: {
                    click: function ($event) {
                      return _vm.save("DELETE")
                    },
                  },
                },
                [_vm._v(" 删除 ")]
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }