<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="back"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
    </fb-header>
    <!-- 用章项目 -->
    <van-popup v-model="addPopupVisible" position="top" get-container="body">
      <chapter-project
        :options="addPopupOptions"
        :result="result"
        @select="handleAddSelect"
      />
    </van-popup>
    <!-- 用章类型 -->
    <van-popup v-model="stampTypeVisible" position="top" get-container="body">
      <stamp-type
        :options="stampTypeOptions"
        :radio="radio"
        @select="handleStampTypeSelect"
      />
    </van-popup>
    <!-- 案件列表 -->
    <van-popup v-model="caseListVisible" position="top" get-container="body">
      <case-list :caseId="caseId" @select="handleCaseListSelect" />
    </van-popup>
    <div class="oa-flow">
      <div>
        <div class="oa-stamp">
          <div class="oa-stamp-div" @click="swtichChapterPro">
            <div class="oa-stamp-title">
              <span class="oa-stamp-title-red">*</span>
              <span>用章项目</span>
            </div>
            <div class="oa-stamp-content">
              <span
                :class="chapterProText === '请选择文书' ? '' : 'selsect-span'"
                >{{ chapterProText }}</span
              >
              <van-icon name="arrow-down" color="#CCCCCC" />
            </div>
          </div>
          <div
            class="oa-stamp-div"
            @click="switchCaseList"
            v-if="title === '案件用章'"
          >
            <div class="oa-stamp-title">
              <span class="oa-stamp-title-red">*</span>
              <span>项目名称</span>
            </div>
            <div class="oa-stamp-content">
              <span :class="caseName === '请选择案件' ? '' : 'selsect-span'">{{
                caseName
              }}</span>
              <van-icon
                name="arrow-down"
                color="#CCCCCC"
                v-if="caseNameIsClick"
              />
            </div>
          </div>
          <div class="oa-stamp-div" @click="swtichStampType">
            <div class="oa-stamp-title">
              <span class="oa-stamp-title-red">*</span>
              <span>用章类型</span>
            </div>
            <div class="oa-stamp-content">
              <span
                :class="
                  stampTypeText === '请选择用章类型' ? '' : 'selsect-span'
                "
                >{{ stampTypeText }}</span
              >
              <van-icon name="arrow-down" color="#CCCCCC" />
            </div>
          </div>
          <div class="oa-stamp-div" v-if="title === '业务用章'">
            <div class="oa-stamp-title">
              <span class="oa-stamp-title-red"></span>
              <span>收函方</span>
            </div>
            <div class="oa-stamp-content">
              <van-field
                v-model="recipient"
                placeholder="请输入收函方"
                input-align="right"
              />
            </div>
          </div>
          <div class="oa-stamp-div" v-if="title === '业务用章'">
            <div class="oa-stamp-title">
              <span class="oa-stamp-title-red">*</span>
              <span>申请份数</span>
            </div>
            <div class="oa-stamp-content">
              <van-field
                v-model="number"
                placeholder="请输入申请份数"
                input-align="right"
                type="digit"
              />
            </div>
          </div>
        </div>
        <div class="oa-stamp-field">
          <van-field
            v-model="remarks"
            rows="2"
            autosize
            label="备注"
            type="textarea"
            maxlength="50"
            placeholder="请输入备注"
            show-word-limit
            class="oa-stamp-van-field"
          />
          <div class="oa-stamp-file">
            <img src="../../../../../public/img/icons/u4647-1.png" alt="" />
            <input type="file" id="file" class="filepath" @change="getImage" />
          </div>
        </div>

        <div class="oa-stamp" v-if="collections.length > 0">
          <div class="oa-stamp-image">
            <div
              class="oa-stamp-image-box"
              v-for="(items, i) in collections"
              :key="i"
            >
              <i
                v-if="items.type === 'WORD'"
                class="iconfont icon-word"
                style="color: rgb(108, 168, 255);"
              ></i>
              <i
                v-if="items.type === 'EXCEL'"
                class="iconfont icon-exl"
                style="color: rgb(139, 221, 108);"
              ></i>
              <i
                v-if="items.type === 'PDF'"
                class="iconfont icon-PDF"
                style="color: rgb(62, 179, 240);"
              ></i>
              <i
                v-if="items.type === 'VIDEO'"
                class="iconfont icon-shipin"
                style="color: rgb(75, 158, 251);"
              ></i>
              <i
                v-if="items.type === 'AUDIO'"
                class="iconfont icon-shipin"
                style="color: rgb(75, 158, 251);"
              ></i>
              <img
                v-if="items.type === 'IMAGE'"
                :src="items.content"
                alt=""
                class="oa-stamp-image-img"
              />
              <img
                src="../../../../../public/img/icons/icon_delete.png"
                alt=""
                class="oa-stamp-image-delete"
                @click="deleteImg(i)"
              />
            </div>
          </div>
        </div>
        <div
          class="oa-approval"
          v-if="this.isAdd === 1 && this.case.progress === 'CERTIFIED_FAILED'"
        >
          <div class="oa-approval-title" style="margin-bottom:20px">
            审批记录
          </div>
          <approval-record
            :typeId="this.case.id"
            :type="'STAMPER_RECORD_ADD'"
          />
        </div>
        <approval-user
          v-else-if="approvalUserComp"
          :Approval="this.Approval"
          :CC="this.CC"
          :isAdd="isAdd"
          :approvalUserSource="approvalUser"
          :ccUserSource="ccUser"
          @userSelect="userSelect"
          @coopSelect="coopSelect"
        />
      </div>
      <div class="oa-button-view">
        <button
          class="oa-button-apply"
          @click="save('UNAUDITED')"
          v-if="this.Approval.length > 0 && this.CC.length > 0"
        >
          申请
        </button>
        <button
          class="oa-button-delete"
          @click="save('DELETE')"
          v-if="caseNameIsClick === false"
        >
          删除
        </button>
      </div>
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import { mapState, mapActions } from 'vuex'
import ChapterProject from '../../components/chapterProject'
import StampType from '../../components/stampType'
import CaseList from '../../components/caseList'
import { qiniuBase } from '~api-config'
import ApprovalRecord from '../../components/approvalRecord'
import ApprovalUser from '../../components/approvalUser'
import { gettype } from '../../common/fileType'

export default {
  name: 'oa-addStamp',
  mixins: [goBackMixin],
  components: {
    ChapterProject,
    StampType,
    CaseList,
    ApprovalRecord,
    ApprovalUser
  },
  data() {
    return {
      isAdd: 0,
      title: '',
      addPopupVisible: false,
      chapterProText: '请选择文书',
      addPopupOptions: [],
      result: [],
      stampTypeVisible: false, // 用章类型弹框
      stampTypeText: '请选择用章类型',
      stampTypeOptions: [],
      radio: '', // 用章类型选中
      CC: [], // 抄送人列表
      Approval: [], // 审批人列表
      ccUser: {}, // 当前选中的抄送人
      approvalUser: {}, // 当前选中的审批人
      approvalUserComp: false, // 是否渲染审批人组件
      coopUser: null,
      caseTypeName: '其他非诉',
      stampTypeName: '案件用章',
      caseListVisible: false,
      caseName: '请选择案件',
      caseId: 0,
      caseNo: '',
      category: '',
      remarks: '', // 备注
      recipient: '', // 收瀚方
      number: 1, // 申请份数
      caseNameIsClick: true, // 项目名称能否点击
      collections: [] // 附件
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id
    }),
    ...mapState('user', ['organizationId'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    back() {
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.$router.back()
      }
    },
    // 用章项目点击事件
    swtichChapterPro() {
      this.addPopupVisible = !this.addPopupVisible
    },
    handleAddSelect(result) {
      console.log('handleAddSelect', result)
      this.result = result
      this.chapterProText = ''
      for (let j = 0; j < this.result.length; j++) {
        for (let i = 0; i < this.addPopupOptions.length; i++) {
          if (this.result[j] === this.addPopupOptions[i].value) {
            this.chapterProText =
              this.chapterProText + ',' + this.addPopupOptions[i].label
          }
        }
      }
      if (result.length === 0) {
        this.chapterProText = '请选择文书'
      } else {
        this.chapterProText = this.chapterProText.substring(
          1,
          this.chapterProText.length
        )
      }
      this.swtichChapterPro()
    },
    // 用章类型点击事件
    swtichStampType() {
      this.stampTypeVisible = !this.stampTypeVisible
    },
    handleStampTypeSelect(options) {
      this.stampTypeText = options.text
      this.radio = options.value
      this.swtichStampType()
    },
    // 获取用章类型
    getTypes() {
      this.stampTypeOptions = []
      this.$axios
        .get(`${this.$base}/management/stamper/types/${this.organizationId}`)
        .then(res => {
          res.data.data.forEach((item, index) => {
            this.stampTypeOptions.push({
              value: item.type,
              text: item.type
            })
          })
          this.stampTypeText = this.stampTypeOptions[0].text
          this.radio = this.stampTypeOptions[0].value
        })
    },
    // 获取审批人和抄送人
    async getUsers() {
      console.log(
        this.caseTypeName,
        this.stampTypeName,
        '-----获取审批人和抄送人'
      )
      this.approvalUserComp = false
      var params = {
        organizationId: this.organizationId,
        caseTypeName: this.stampTypeName,
        type: 'STAMPER_RECORD_ADD'
      }
      const res = await this.$axios.post(
        `${this.$base}/management/org/advice/user`,
        params
      )
      this.approvalUserComp = true
      const { code, data } = res.data
      if (code === 200 && data.length > 0) {
        const arr = [] // 审批
        const arrlist = [] // 抄送
        data.forEach(item => {
          if (item.type === 'APPROVER') {
            arr.push({
              label: item.userName,
              userid: item.userId,
              type: 'APPROVER',
              avatarUrl: item.userName
            })
          } else if (item.type === 'RECIPIENT') {
            arrlist.push({
              label: item.userName,
              userid: item.userId,
              type: 'RECIPIENT',
              avatarUrl: item.userName
            })
          }
        })
        this.CC = arrlist
        this.Approval = arr
        if (this.isAdd === 0) {
          this.ccUser = arrlist[0]
          this.approvalUser = arr[0]
        }
      }
    },
    /**
     * 处理审批流程组件返回的审批人和抄送人
     * 返回userList数组
     * 第一个元素是审批人
     * 第二个元素是抄送人
     */
    userSelect(userList) {
      this.approvalUser = userList[0]
      this.ccUser = userList[1]
    },
    // 协作人
    coopSelect(coopUser) {
      this.coopUser = coopUser
    },
    // 获取用章项目
    GetType() {
      this.$axios
        .get(
          `${this.$base}/management/organization/${
            this.organizationId
          }/stamper/items`
        )
        .then(res => {
          const arr = []
          if (res.data.data) {
            res.data.data.forEach((item, index) => {
              arr.push({
                value: item.item,
                label: item.item
              })
            })
            this.addPopupOptions = arr
          }
        })
    },
    // 项目名称点击事件
    switchCaseList() {
      if (this.caseNameIsClick) {
        this.caseListVisible = !this.caseListVisible
      }
    },
    handleCaseListSelect(options) {
      this.caseName = options.label
      this.caseId = options.caseId
      this.caseNo = options.caseNo
      this.caseTypeName = options.caseTypeName
      this.getUsers()
      this.switchCaseList()
    },
    // 删除事件
    deleteCase() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '删除不可恢复！确定删除吗？'
        })
        .then(async () => {
          // on confirm
          const response = await this.$axios.post(
            `${this.$base}/management/stamper/record/delete/${this.case.id}`
          )
          const { code } = response.data
          if (code === 200) {
            this.$toast.success('删除成功')
            setTimeout(() => {
              this.back()
            }, 1000)
          }
        })
        .catch(() => {
          // on cancel
        })
    },
    // 修改案件事件
    updateCase(progress) {
      const userObj = []
      this.approveRecordUsers.map(item => {
        if (item.type === 'APPLICANT') {
          userObj.push({
            type: 'APPLICANT',
            user: {
              id: item.user.id
            }
          })
        }
      })
      userObj.push({
        type: 'APPROVER',
        user: {
          id: this.approvalUser.userid
        }
      })
      userObj.push({
        type: 'RECIPIENT',
        user: {
          id: this.ccUser.userid
        }
      })
      const params = {
        approveRecordUsers: this.case.approveRecordUsers,
        category: this.case.category,
        collections: this.collections,
        progress: progress,
        recipient: this.recipient,
        remarks: this.remarks,
        stamperItem: this.chapterProText,
        type: this.stampTypeText,
        useTime: new Date(this.useTime).getTime(),
        number: this.number
      }
      if (this.case.category === 'CASE') {
        delete params.number
      }
      this.$axios
        .put(
          `${this.$base}/management/stamper/record/${this.case.id}/progress`,
          params
        )
        .then(res => {
          if (res.data.code === 200) {
            this.$toast.success('保存成功')
            this.back()
          } else {
            this.$toast.fail('保存失败')
          }
        })
    },
    // 保存或者提交事件
    save(progress) {
      if (progress === 'DELETE') {
        this.deleteCase()
      } else {
        // 新增事件
        if (this.caseNameIsClick) {
          if (this.result.length === 0) {
            this.$toast('请选择用章项目')
            return
          }
          if (this.radio === '') {
            this.$toast('请选择用章类型')
            return
          }
          if (this.category === 'CASE') {
            // 案件用章
            if (this.caseNo === '') {
              this.$toast('请选择项目名称')
              return
            }
          } else {
            if (this.number === '') {
              this.$toast('请输入申请份数')
              return
            }
          }
          const userObj = []
          userObj[0] = {
            type: 'APPLICANT',
            user: {
              id: this.userId
            }
          }
          userObj[1] = {
            type: 'APPROVER',
            user: {
              id: this.approvalUser.userid
            }
          }
          userObj[2] = {
            type: 'RECIPIENT',
            user: {
              id: this.ccUser.userid
            }
          }
          if (this.coopUser) {
            userObj.push({
              type: 'APPROVER',
              user: {
                id: this.coopUser.id
              }
            })
          }
          let params = {}
          if (this.category === 'CASE') {
            // 案件用章
            params = {
              category: this.category,
              progress: progress,
              remarks: this.remarks,
              caseId: this.caseId,
              caseNo: this.caseNo,
              collections: this.collections,
              itemName: this.caseName,
              recipient: this.recipient, // 收瀚方
              type: this.stampTypeText,
              stamperItem: this.chapterProText,
              useTime: new Date().getTime(),
              approveRecordUsers: userObj
            }
          } else {
            params = {
              category: this.category,
              progress: progress,
              remarks: this.remarks,
              collections: this.collections,
              recipient: this.recipient, // 收瀚方
              type: this.stampTypeText,
              stamperItem: this.chapterProText,
              useTime: new Date().getTime(),
              approveRecordUsers: userObj,
              number: this.number.toString()
            }
          }
          this.$axios
            .post(
              `${this.$base}/management/stamper/record/${this.organizationId}`,
              params
            )
            .then(res => {
              if (res.data.code === 200) {
                this.$toast.success('提交成功')
                this.back()
              } else {
                // this.$message.error(res.data.message)
              }
            })
        } else {
          // 修改事件
          this.updateCase(progress)
        }
      }
    },

    // 格式化修改参数
    formatCase(caseObj) {
      this.remarks = caseObj.remarks
      this.recipient = caseObj.recipient
      if (caseObj.category === 'WORK') {
        this.number = caseObj.number
      }
      this.stampTypeText = caseObj.type
      this.radio = caseObj.type
      this.caseName = caseObj.itemName
      this.chapterProText = caseObj.stamperItem
      this.result = caseObj.stamperItem.split(',')
      this.approveRecordUsers = caseObj.approveRecordUsers
      this.approveRecordUsers.map(item => {
        if (item.type === 'APPROVER') {
          this.approvalUser = {
            label: item.user.nickName,
            userid: item.user.id,
            type: 'APPROVER',
            avatarUrl: item.user.avatarUrl
          }
        } else if (item.type === 'RECIPIENT') {
          this.ccUser = {
            label: item.user.nickName,
            userid: item.user.id,
            type: 'RECIPIENT',
            avatarUrl: item.user.avatarUrl
          }
        }
      })
      this.useTime = caseObj.useTime
      this.collections = caseObj.collections
    },

    // 从手机相册获取图片
    getImage(e) {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(1)
        return false
      } else {
        console.log(e)
        const f = document.getElementById('file').files[0]
        const qiniu = new this.$qiniu()
        qiniu.UploadFile(
          'DEFAULT',
          f,
          {
            next: file => {},
            complete: (res, file) => {},
            error: () => {
              this.$toast('上传失败，请重新选择')
            }
          },
          (fileopt, key, file) => {
            const url = qiniuBase + '/' + key
            if (!gettype(fileopt.name)) {
              return this.$notify('文件格式不支持')
            }
            var obj = {
              name: fileopt.name,
              type: gettype(fileopt.name),
              content: url,
              size: fileopt.size
            }
            this.collections.push(obj)
          }
        )
      }
    },

    // 接收原生端上传回调
    uploadFilesResultCallBack(data) {
      console.log(data)
      const list = JSON.parse(data)
      list.map(item => {
        if (!gettype(item.name)) {
          return this.$notify('文件格式不支持')
        }
        var obj = {
          name: item.name,
          type: gettype(item.name),
          content: item.url,
          size: item.size
        }
        this.collections.push(obj)
      })
    },

    // 删除图片
    deleteImg(index) {
      this.collections.splice(index, 1)
    },

    // 获取组织id
    async getId() {
      await this.getOrganizationId()
    }
  },
  async created() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
    await this.getId()
    this.getTypes()
    this.getUsers()
    this.GetType()
  },
  mounted() {
    this.title = this.$route.query.label
    this.isAdd = parseInt(this.$route.query.isAdd)
    this.category = this.$route.query.label === '案件用章' ? 'CASE' : 'WORK'
    this.stampTypeName = this.$route.query.label
    if (this.$route.query.case !== '') {
      this.case = JSON.parse(this.$route.query.case)
      console.log(this.case)
      this.formatCase(this.case)
      this.caseNameIsClick = false
    }
  }
}
</script>

<style lang="stylus">
.approval-title
  text-align center
  height 40px
  line-height 40px
  font-size 16px
  color rgba(69, 90, 100, 0.6)
.oa-flow
  position relative
  padding-bottom 80px
  box-sizing border-box
  & .van-cell
    padding-left 18px !important
  & .oa-stamp
    padding 0 18px
    box-sizing border-box
    & .oa-stamp-div
      width 100%
      height 50px
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      & .oa-stamp-title
        position relative
        span
          font-size 14px
        & .oa-stamp-title-red
          color red
          position absolute
          left -10px
      & .oa-stamp-content
        position relative
        height 100%
        margin-left 10px
        display flex
        flex-direction row
        align-items center
        span
          color #CCCCCC
          font-size 14px
          margin-right 5px
          & .van-dropdown-menu.van-hairline--top-bottom::after
            border-width 0
        & .selsect-span
          color #000000
    & .oa-stamp-image
      display flex
      flex-direction row
      align-items center
      padding 20px 0
      box-sizing border-box
      flex-wrap wrap
      & .oa-stamp-image-box
        position relative
        width 70px
        height 70px
        margin-right 10px
        margin-bottom 10px
        & .iconfont
          font-size 70px
        & .oa-stamp-image-delete
          position absolute
          width 20px
          height 20px
          right -10px
          top -10px
        & .oa-stamp-image-img
          width 100%
          height 100%
          object-fit contain
  & .oa-stamp-field
    display flex
    flex-direction row
    padding-right 18px
    box-sizing border-box
    & .oa-stamp-file
      margin-top 10px
      width 20px
      height 23px
      position relative
      img
        width 20px
        height 23px
      input
        width 20px
        height 23px
        position absolute
        left 0
        top 0
        opacity 0
  & .oa-approval
    border-top 10px solid rgba(242, 242, 242, 1)
    padding 20px
    box-sizing border-box
    position relative
    & .oa-approval-title
      font-size 14px
      color #CCCCCC
    & .oa-approval-step
      height 80px
      padding 0 20px
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      border-bottom 1px solid rgba(242, 242, 242, 1)
      font-size 14px
      & .oa-approval-step-div
        display flex
        flex-direction row
        align-items center
        & .oa-approval-step-dot
          width 10px
          height 10px
          border-radius 5px
          background rgba(242, 242, 242, 1)
          margin-right 10px
      & .oa-approval-userInfo
        display flex
        flex-direction column
        align-items flex-end
        img
          width 30px
          height 30px
          border-radius 15px
    & .oa-approval-step-line
      width 2px
      height 80px
      background rgba(242, 242, 242, 1)
      position absolute
      bottom 60px
      left 44px
.oa-button-view
  width 100%
  background #FFFFFF
  position fixed
  bottom 0px
  right 0px
  padding 20px
  box-sizing border-box
  display flex
  flex-direction row
  justify-content flex-end
  button
    width 80px
    height 50px
    border-radius 5px
    font-size 16px
  & .oa-button-save
    background none
    border none
    color #0079FE
  & .oa-button-apply
    background #0079FE
    border none
    color #FFFFFF
  & .oa-button-delete
    background none
    border 1px solid #0079FE
    color #0079FE
</style>
